define("ralphandcelena/components/ll-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let LlFooter = classic(_class = class LlFooter extends Ember.Component {}) || _class;

  _exports.default = LlFooter;
});