define("ralphandcelena/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hmU6oU21",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"masthead text-center text-white is-home\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"masthead-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"masthead-heading mb-0\"],[12],[2,\"Whoops!\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"masthead-subheading mb-0\"],[12],[2,\"That page doesn't exist.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bg-circles\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-1 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-2 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-3 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-4 bg-circle\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/404.hbs"
    }
  });

  _exports.default = _default;
});