define("ralphandcelena/app", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-resolver", "ember-load-initializers", "ralphandcelena/config/environment"], function (_exports, _defineProperty2, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "modulePrefix", _environment.default.modulePrefix);
      (0, _defineProperty2.default)(this, "podModulePrefix", _environment.default.podModulePrefix);
      (0, _defineProperty2.default)(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;

  window.__CLASSIC_OWN_CLASSES__.set(App, true);

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});