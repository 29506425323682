define("ralphandcelena/components/rsvp-form", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    email: null,
    areYouComing: null,
    accomodation: null,
    travel: null,
    areYouVegetarian: null,
    allergies: null,
    doYouHaveKids: null,
    babysitter: null,

    init() {
      this._super(...arguments);

      this.set('success', false);
    },

    hideSubmit: Ember.computed('areYouComing', function () {
      return this.areYouComing === null || this.areYouComing === undefined;
    }),
    actions: {
      submit() {
        if (this.areYouComing === null || this.areYouComing === undefined) {
          this.handleError('Are you coming?');
        } else {
          const data = {
            name: this.name,
            email: this.email,
            areYouComing: this.areYouComing,
            accomodation: this.accomodation,
            travel: this.travel,
            areYouVegetarian: this.areYouVegetarian,
            allergies: this.allergies,
            doYouHaveKids: this.doYouHaveKids,
            babysitter: this.babysitter
          };
          const request = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          };
          const call = (0, _fetch.default)('/api/rsvp/index.php', request);
          call.then(({
            response
          }) => this.handleSuccess(response)).catch(() => this.handleError('There was a problem with your request, please try again later or use the contact page'));
        }

        window.scrollTo(0, 0);
      }

    },

    handleSuccess() {
      this.set('success', true);
    },

    handleError(msg) {
      this.set('error', msg);
    }

  });

  _exports.default = _default;
});