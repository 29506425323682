define("ralphandcelena/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pKVF+WT7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ll-header\",[],[[\"@collapsedMenu\",\"@toggleLang\",\"@toggleMenu\"],[[32,0,[\"collapsedMenu\"]],[30,[36,0],[[32,0],\"toggleLang\"],null],[30,[36,0],[[32,0],\"toggleMenu\"],null]]],null],[2,\"\\n\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/application.hbs"
    }
  });

  _exports.default = _default;
});