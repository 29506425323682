define("ralphandcelena/templates/components/ll-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kJYl+RZV",
    "block": "{\"symbols\":[\"layers\",\"marker\",\"marker\",\"marker\",\"marker\"],\"statements\":[[8,\"leaflet-map\",[[24,0,\"map\"]],[[\"@lat\",\"@lng\",\"@zoom\"],[[34,0],[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"tile\"]],[],[[\"@url\"],[\"https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png\"]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"marker\"]],[],[[\"@location\"],[[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,5,[\"popup\"]],[],[[\"@popupOpen\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Les Condamines\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        26110 St Ferréol-Trente-Pas\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[5]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"marker\"]],[],[[\"@location\"],[[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"popup\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Marseille\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"marker\"]],[],[[\"@location\"],[[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"popup\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Montpellier\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"marker\"]],[],[[\"@location\"],[[34,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"popup\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Lyon\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mapCenterLat\",\"mapCenterLng\",\"zoom\",\"venue\",\"mrs\",\"mpl\",\"lys\"]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/ll-map.hbs"
    }
  });

  _exports.default = _default;
});