define("ralphandcelena/templates/components/generic-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aDDrNNhG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"header\"],[15,0,[31,[\"masthead bg-\",[32,0,[\"bg\"]],\" \",[30,[36,1],[[32,0,[\"headerTitle\"]],\"has-title\"],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"headerTitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"display-4 text-white has-shadow\"],[12],[1,[30,[36,0],[[32,0,[\"headerTitle\"]],[32,0,[\"lang\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[18,1,[[32,0]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/generic-layout.hbs"
    }
  });

  _exports.default = _default;
});