define("ralphandcelena/routes/application", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ralphandcelena/config/environment"], function (_exports, _applyDecoratedDescriptor2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ApplicationRoute = (_dec = Ember._action, classic(_class = (_class2 = class ApplicationRoute extends Ember.Route {
    didTransition() {
      if (_environment.default.environment === 'production' && window.location.protocol == 'http:') {
        window.location.protocol = 'https:';
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "didTransition"), _class2.prototype)), _class2)) || _class);
  _exports.default = ApplicationRoute;
});