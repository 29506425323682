define("ralphandcelena/templates/components/ll-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rqk4iQvG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"footer\"],[14,0,\"py-5 bg-black\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"m-0 text-center text-white small\"],[12],[2,\"© Ralph & Celena\"],[13],[2,\"\\n  \"],[13],[2,\"    \\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/ll-footer.hbs"
    }
  });

  _exports.default = _default;
});