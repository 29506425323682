define("ralphandcelena/initializers/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component', 'applicationController', 'controller:application');
    Ember.Component.reopen({
      lang: Ember.computed.alias('applicationController.lang')
    });
    Ember.Controller.reopen({
      init() {
        this._super(...arguments);

        const name = this.toString();
        const isApplication = name.indexOf('controller:application') > -1;
        this.set('isApplication', isApplication);

        if (!isApplication) {
          Ember.defineProperty(this, 'applicationController', Ember.inject.controller('application'));
          Ember.defineProperty(this, 'lang', Ember.computed.alias('applicationController.lang'));
        }
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});