define("ralphandcelena/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ralphandcelena/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;

  window.__CLASSIC_OWN_CLASSES__.set(Router, true);

  Router.map(function () {
    // this.route('rsvp');
    this.route("lieu");
    this.route("hebergements"); // this.route('contact');

    this.route("big-day");
    this.route("404", {
      path: "/*path"
    });
  });
});