define("ralphandcelena/templates/rsvp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7pC9xA9",
    "block": "{\"symbols\":[\"l\"],\"statements\":[[8,\"generic-layout\",[],[[\"@bg\",\"@headerTitle\"],[\"rchands\",\"titles.rsvp\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"p-5\"],[12],[2,\"\\n    \"],[8,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"info\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"rsvp.let us know\",[32,1,[\"lang\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"h2\"],[14,0,\"display-5\"],[12],[1,[30,[36,0],[\"rsvp.are you coming\",[32,1,[\"lang\"]]],null]],[13],[2,\"\\n    \\n    \"],[8,\"rsvp-form\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/rsvp.hbs"
    }
  });

  _exports.default = _default;
});