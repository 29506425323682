define("ralphandcelena/components/polygon-layer", ["exports", "ember-leaflet/components/polygon-layer"], function (_exports, _polygonLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _polygonLayer.default;
    }
  });
});