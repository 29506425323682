define("ralphandcelena/routes/hebergements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let HebergementsRoute = classic(_class = class HebergementsRoute extends Ember.Route {}) || _class;

  _exports.default = HebergementsRoute;
});