define("ralphandcelena/components/ll-map", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let LlMap = classic(_class = (_temp = class LlMap extends Ember.Component {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "latOffset", 1);
      (0, _defineProperty2.default)(this, "lat", 44.4379219);
      (0, _defineProperty2.default)(this, "lng", 5.2386151);
      (0, _defineProperty2.default)(this, "zoom", 14);
      (0, _defineProperty2.default)(this, "venue", [this.lat, this.lng]);
      (0, _defineProperty2.default)(this, "mapCenterLat", this.lat - this.latOffset);
      (0, _defineProperty2.default)(this, "mapCenterLng", this.lng);
      (0, _defineProperty2.default)(this, "mrs", [43.4384274, 5.2122253]);
      (0, _defineProperty2.default)(this, "mpl", [43.5795991, 3.9652372]);
      (0, _defineProperty2.default)(this, "lys", [45.7226909, 5.0701684]);
    }

  }, _temp)) || _class;

  _exports.default = LlMap;
});