define("ralphandcelena/controllers/application", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _temp;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ApplicationController = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = (_temp = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "collapsedMenu", true);
    }

    init() {
      super.init(...arguments);
      const lang = navigator.language || navigator.userLanguage;
      this.set('lang', lang.substr(0, 2));
    }

    toggleMenu() {
      this.toggleProperty('collapsedMenu');
    }

    toggleLang(lang) {
      this.set('lang', lang);
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleMenu", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleMenu"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleLang", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleLang"), _class2.prototype)), _class2)) || _class);
  _exports.default = ApplicationController;
});