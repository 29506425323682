define("ralphandcelena/controllers/hebergements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HebergementsController extends Ember.Controller {
    boom() {
      alert('BOOM !');
    }

  }

  _exports.default = HebergementsController;

  window.__CLASSIC_OWN_CLASSES__.set(HebergementsController, true);
});