define("ralphandcelena/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fDbYRtH/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"masthead text-center text-white is-home\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"masthead-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"masthead-heading mb-0\"],[12],[2,\"Ralph \"],[8,\"ll-amp\",[],[[],[]],null],[2,\" Celena\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"masthead-subheading mb-0\"],[12],[1,[30,[36,0],[\"the date\",[32,0,[\"lang\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bg-circles\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-1 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-2 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-3 bg-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-circle-4 bg-circle\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ralphandcelena/templates/index.hbs"
    }
  });

  _exports.default = _default;
});